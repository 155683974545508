import { ApiServiceBase } from '~/api/services/ApiServiceBase'
import type { ApiResponse } from '~/api/types/Api'

export default class InsureService extends ApiServiceBase {
  async questionschema(): Promise<ApiResponse<object>> {
    return await this.call('/v1/insure/question-schema')
  }
  async claimchema(): Promise<ApiResponse<object>> {
    return await this.call('/v1/insure/claim-schema')
  }
  async vehicle(postData: object): Promise<ApiResponse<object>> {
    return await this.call('/v1/insure/vehicles', postData)
  }
  async store(postData: object): Promise<ApiResponse<object>> {
    const response = await this.call('/v1/insure/store', postData)
    await useFetch(`/api/zendesk/insure`, {
      method: 'POST',
      body: response,
    })
    return response
  }
  async list(): Promise<ApiResponse<object>> {
    return await this.call('/v1/insure/quotes')
  }
  async show(id: string): Promise<ApiResponse<object>> {
    return await this.call(`/v1/insure/quotes/${id}`)
  }
}
