import { ApiServiceBase } from '~/api/services/ApiServiceBase'
import type { ApiPaginatedResponse, ApiResponse } from '~/api/types/Api'

export default class PayoutService extends ApiServiceBase {
  async list(query?: object | null): Promise<ApiPaginatedResponse<object>> {
    return await this.call('/v1/payouts', query ?? {})
  }

  async get(id: string): Promise<ApiResponse<object>> {
    return await this.call(`/v1/payouts/${id}`)
  }

  async enablePayouts(): Promise<ApiResponse<object>> {
    return await this.call(`/v1/payouts/enable`, {
      method: 'POST',
    })
  }

  async disablePayouts(): Promise<ApiResponse<object>> {
    return await this.call(`/v1/payouts/disable`, {
      method: 'POST',
    })
  }
}
