<template>
  <div class="mx-auto flex min-h-screen max-w-screen-md flex-col bg-gray-50 text-gray-800">
    <header class="sidebar black-bg flex items-center py-2">
      <img class="w-[200px] p-3" src="~assets/images/main-logo.png" alt="Veezu Partners" />
    </header>

    <main class="-mt-12 flex flex-grow flex-col items-center justify-center p-3 text-center">
      <h1 class="mb-4 text-xl font-bold text-red-500">{{ error?.statusCode }} - {{ statusText }}</h1>
      <p class="mb-6 text-sm">
        {{ errorMessage }}
      </p>
      <button class="btn-pink" @click="home">Go back to home</button>
    </main>
  </div>
</template>

<script setup lang="ts">
import type { NuxtError } from '#app'

const props = withDefaults(
  defineProps<{
    error: NuxtError
  }>(),
  {
    error: () => ({}),
  }
)

const statusTextMapping: Record<number, string> = {
  403: 'Forbidden',
  404: 'Not Found',
  429: 'Too Many Requests',
}

const statusText = computed(() => {
  return statusTextMapping[props.error?.statusCode] || 'Unknown Error'
})

const errorMessage = computed(() => {
  switch (props.error?.statusCode) {
    case 403:
      return "Sorry, you don't have permission to access this page."
    case 404:
      return "The page you are looking for doesn't exist."
    case 429:
      return 'You have made too many requests, please try again in a minute.'
    default:
      return 'Sorry, something has gone wrong. We have alerted our engineering team of the issue. Please try your request again.'
  }
})

function home() {
  return navigateTo('/')
}
</script>
